import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { logout } from 'shared/slices/userSlice';
import ButtonLink from '@thrivetrm/ui/components/ButtonLink';

import './LoggedInContainer.scss';

const LoggedInContainer = ({ children }) => {
  const dispatch = useDispatch();
  return (
    <div className='LoggedInContainer'>
      <div className='LoggedInContainer__content'>{children}</div>
      <footer className='LoggedInContainer__footer'>
        <ButtonLink
          className='LoggedInContainer__logoutButton'
          label='Log Out'
          onClick={() => dispatch(logout())}
        />
      </footer>
    </div>
  );
};

LoggedInContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoggedInContainer;
